import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { IoChatbubbles } from 'react-icons/io5';
import Chatbot from './Chatbot';

const pulse = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(106, 17, 203, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(106, 17, 203, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(106, 17, 203, 0);
  }
`;

const shimmer = keyframes`
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
`;

const ChatbotLauncherButton = styled.button`
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: linear-gradient(135deg, #00c6ff 0%, #0072ff 100%);
  color: white;
  border: none;
  box-shadow: 0 4px 15px rgba(106, 17, 203, 0.6);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  z-index: 9999;
  transition: all 0.3s ease;
  animation: ${pulse} 2s infinite;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.3) 50%,
      rgba(255, 255, 255, 0) 100%
    );
    transform: rotate(30deg);
    animation: ${shimmer} 3s infinite;
  }

  &:hover {
    transform: scale(1.1);
    box-shadow: 0 6px 20px rgba(106, 17, 203, 0.8);
  }
`;

const IconWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`;

// Conteneur du chatbot
const ChatbotContainer = styled.div`
  position: fixed;
  bottom: 120px;
  right: 30px;
  width: 380px;
  height: 600px;
  border-radius: 20px;
  background: linear-gradient(135deg, #2a2a72 0%, #009ffd 100%);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  display: ${props => (props.visible ? 'block' : 'none')};
  z-index: 9998;  // Juste en dessous du z-index du bouton launcher
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  
  ${props => props.visible && `
    animation: slideIn 0.3s forwards;
  `}

  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @media (max-width: 480px) {
    width: 100%;
    height: calc(100% - 100px);  // Laisse de l'espace pour le bouton launcher
    bottom: 100px;
    right: 0;
    border-radius: 20px 20px 0 0;
  }
`;

const ChatbotLauncher = () => {
    console.log("ChatbotLauncher monté");
    const [visible, setVisible] = useState(false);
  
    const toggleChatbot = () => {
      console.log('Toggling chatbot, current visibility:', visible);
      setVisible(!visible);
    };
  
    return (
      <>
        <ChatbotLauncherButton onClick={toggleChatbot} aria-label="Open chatbot">
          <IconWrapper>
            <IoChatbubbles />
          </IconWrapper>
          IA
        </ChatbotLauncherButton>
        {visible && (
          <ChatbotContainer visible={visible}>
            <Chatbot />
          </ChatbotContainer>
        )}
      </>
    );
  };
export default ChatbotLauncher;
