import React from 'react';
import GlobalStyle from './components/GlobalStyles';
import styled from 'styled-components';
import ChatbotLauncher from './components/ChatbotLauncher';

const AppContainer = styled.div`
    position: relative; // Ajoutez cette ligne
    min-height: 100vh; // Changez height en min-height
    width: 100%;
    background: linear-gradient(135deg, #667eea, #764ba2);
    font-family: 'Roboto', sans-serif;
`;

function App() {
  console.log("Script React exécuté et rendu");
    return (
        <AppContainer>
            <GlobalStyle />
            
            <ChatbotLauncher />
            
        </AppContainer>
    );
}

export default App; 