import styled, { keyframes } from 'styled-components';

const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const pulseRed = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(203, 17, 17, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(203, 17, 17, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(203, 17, 17, 0);
  }
`;

export const ChatbotContainer = styled.div`
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background: linear-gradient(135deg, #2a2a72 0%, #009ffd 100%);
    color: #ffffff;
    border-radius: 20px;
    box-shadow: 0 8px 32px rgba(31, 38, 135, 0.15);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.18);
    // padding: 20px;
    overflow: hidden;

    @media (max-width: 768px) {
        width: 90%;
        height: 70%;
        bottom: 50px;
        right: 5%;
        left: 5%;
        border-radius: 15px;
    }

    @media (max-width: 480px) {
        width: 100%;
        height: 100%;
        bottom: 0;
        right: 0;
        left: 0;
        border-radius: 0;
    }
`;

export const MessagesContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 70px; // Laisse de l'espace pour l'InputContainer
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    padding: 20px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.2);
`;

export const Message = styled.div`
    background: ${props => props.sender === 'user' ? 'linear-gradient(45deg, #00c6ff 0%, #0072ff 100%)' : 'rgba(255, 255, 255, 0.2)'};
    color: ${props => props.sender === 'user' ? 'white' : '#f0f0f0'};
    padding: 12px 18px;
    border-radius: 18px;
    margin-bottom: 10px;
    align-self: ${props => props.sender === 'user' ? 'flex-end' : 'flex-start'};
    max-width: 80%;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    animation: ${gradientAnimation} 3s ease infinite;
    background-size: 200% 200%;
    transition: all 0.3s ease;

    &:hover {
        transform: translateY(-2px);
        box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
    }

    .message-content {
        font-size: 16px;
        line-height: 1.5;

        h1, h2, h3, h4, h5, h6 {
            margin-top: 0.6em;
            margin-bottom: 0.3em;
            font-weight: 600;
        }

        ul, ol {
            padding-left: 20px;
        }

        li {
            margin-bottom: 0.2em;
        }
    }
    .cta-link {
      display: inline-block;
      margin-top: 10px;
      padding: 8px 15px;
      background: linear-gradient(45deg, #00c6ff 0%, #0072ff 100%);
      color: white;
      text-decoration: none;
      border-radius: 20px;
      font-weight: bold;
      transition: all 0.3s ease;

      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 4px 10px rgba(0, 198, 255, 0.3);
      }
  }
`;

export const InputContainer = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 0px;
    margin: 0px;
    padding: 15px;
`;

export const Input = styled.input`
    flex: 1;
    padding: 10px 15px;
    border: none;
    border-radius: 20px;
    font-size: 16px;
    outline: none;
    background: transparent;
    color: #ffffff;;

    &::placeholder {
        color: rgba(255, 255, 255, 0.7);
    }
`;

export const SendButton = styled.button`
    padding: 10px;
    background: linear-gradient(45deg, #00c6ff 0%, #0072ff 100%);
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    margin-left: 10px;
    transition: all 0.3s ease;

    &:hover {
        transform: scale(1.1);
        box-shadow: 0 0 15px rgba(37, 117, 252, 0.5);
    }
`;

export const StopButton = styled(SendButton)`
    background: linear-gradient(45deg, #cb1111 0%, #fc2525 100%);
    position: relative;
    overflow: hidden;
    width: 40px;  // Assurez-vous que cette valeur correspond à celle du SendButton
    height: 40px; // Assurez-vous que cette valeur correspond à celle du SendButton
    border-radius: 50%;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
        content: '';
        position: absolute;
        top: -50%;
        left: -50%;
        width: 200%;
        height: 200%;
        background: radial-gradient(circle, rgba(252, 37, 37, 0.3) 0%, rgba(252, 37, 37, 0) 70%);
        transform: scale(0);
        transition: transform 0.3s ease-out;
    }

    &:hover {
        transform: scale(1.1);
        box-shadow: 0 0 15px rgba(252, 37, 37, 0.5);
        animation: ${pulseRed} 1.5s infinite;

        &::before {
            transform: scale(1);
        }
    }

    &:active {
        transform: scale(0.95);
    }

    svg {
        position: relative;
        z-index: 2;
    }
`;
