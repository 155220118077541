import React, { useState, useEffect, useRef } from 'react';

const TypewriterEffect = ({ formattedText, onTypingDone }) => {
  const [displayText, setDisplayText] = useState('');
  const intervalRef = useRef(null);
  const textRef = useRef(formattedText);
  const isTypingRef = useRef(true);
  const indexRef = useRef(0);

  useEffect(() => {
    console.log("TypewriterEffect - formattedText reçu:", formattedText);
    if (textRef.current !== formattedText) {
      textRef.current = formattedText;
      setDisplayText('');
      indexRef.current = 0;
      isTypingRef.current = true;
    }

    if (!isTypingRef.current) return;

    const typeNextChar = () => {
      if (indexRef.current < textRef.current.length) {
        setDisplayText(textRef.current.slice(0, indexRef.current + 1));
        indexRef.current++;
      } else {
        clearInterval(intervalRef.current);
        isTypingRef.current = false;
        onTypingDone();
      }
    };

    intervalRef.current = setInterval(typeNextChar, 10);

    return () => clearInterval(intervalRef.current);
  }, [formattedText, onTypingDone]);

  console.log("TypewriterEffect - displayText actuel:", displayText);

  return <div dangerouslySetInnerHTML={{ __html: displayText }} />;
};

export default TypewriterEffect;


