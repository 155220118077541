import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import wonInstructions from '../shared/wonInstructions.json';
import {
  ChatbotContainer,
  MessagesContainer,
  Message,
  InputContainer,
  Input,
  SendButton,
  StopButton
} from './ChatbotStyles';
import { IoSend } from 'react-icons/io5';
import { Spinner } from './Spinner';
import TypewriterEffect from './TypewriterEffect';
import './scrollbar.css';

const Chatbot = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [autoScroll, setAutoScroll] = useState(true);
  const messagesEndRef = useRef(null);
  const messagesContainerRef = useRef(null);
  const [typingMessageId, setTypingMessageId] = useState(null);
  const [isResponding, setIsResponding] = useState(false);

  const apiUrl = process.env.REACT_APP_API_URL + '/chat';

  const scrollToBottom = useCallback(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  useEffect(() => {
    console.log("Messages updated:", messages);
    if (autoScroll) {
      scrollToBottom();
    }
  }, [messages, scrollToBottom, autoScroll]);

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = messagesContainerRef.current;
    const isAtBottom = scrollHeight - scrollTop <= clientHeight + 10;
    setAutoScroll(isAtBottom);
  };

  const handleTypingDone = useCallback((id) => {
    setMessages(prevMessages => 
      prevMessages.map(m => 
        m.id === id ? { ...m, isTyping: false } : m
      )
    );
    setTypingMessageId(null);
  }, []);

  const formatResponse = (text) => {
    if (typeof text !== 'string') return '';
    
    // Titres avec hashtags
    text = text.replace(/^(#{1,3})\s*(.*?)$/gm, (match, hashes, content) => {
      const level = hashes.length;
      return `<h${level} class="chat-heading">${content}</h${level}>`;
    });
    
    // Énumérations avec puces
    text = text.replace(/^[•-]\s*(.*?)$/gm, '<li>$1</li>');
    text = text.replace(/(<li>.*?<\/li>)/gs, '<ul>$1</ul>');
    
    // Texte en gras
    text = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
    
    // Sauts de ligne
    text = text.replace(/\n/g, '<br>');
    // Gestion des liens CTA
    text = text.replace(/\[([^\]]+)\]\(([^\)]+)\)/g, '<a href="$2" target="_blank" rel="noopener noreferrer" class="cta-link">$1</a>');
    
    return text;
  };

  const sendMessage = async (messageText) => {
    if (!input.trim()) return;

    const userMessage = { id: Date.now(), text: input, sender: 'user' };
    setMessages(prevMessages => [...prevMessages, userMessage]);
    setInput('');
    setIsTyping(true);
    setIsResponding(true);

    try {
      const response = await axios.post(apiUrl, {
        message: input,
        context: {
          previous_messages: messages.slice(-5).map(m => ({ text: m.text, sender: m.sender })),
          won_instructions: wonInstructions,
          role_description: wonInstructions.introduction
        }
      });
      console.log("Réponse reçue de l'API:", response.data);

      const botMessageId = Date.now();
      const formattedReply = formatResponse(response.data.reply);
      const newBotMessage = { 
        id: botMessageId, 
        text: formattedReply, 
        sender: 'bot', 
        isTyping: true 
      };
      setMessages(prevMessages => {
        console.log("Ajout du nouveau message:", newBotMessage);
        return [...prevMessages, newBotMessage];
      });
      setTypingMessageId(botMessageId);
    } catch (error) {
      console.error('Erreur avec l\'API:', error);
      if (error.response) {
        console.error('Réponse d\'erreur:', error.response.data);
      }
      const errorMessage = { 
        id: Date.now(), 
        text: "Désolé, je n'ai pas pu traiter votre demande. Pouvez-vous réessayer ?", 
        sender: 'bot' 
      };
      setMessages(prevMessages => [...prevMessages, errorMessage]);
    } finally {
      setIsTyping(false);
      setIsResponding(false);
    }
  };

  const stopResponse = useCallback(() => {
    console.log("Arrêt de la réponse");
    setIsResponding(false);
    setTypingMessageId(null);
    setIsTyping(false);
    setMessages(prevMessages => 
      prevMessages.map(m => 
        m.id === typingMessageId ? { ...m, isTyping: false, text: m.text + ' [Interrompu]' } : m
      )
    );
  }, [typingMessageId]);

  useEffect(() => {
    console.log("isResponding changed:", isResponding);
  }, [isResponding]);

  useEffect(() => {
    console.log("Messages mis à jour:", messages);
  }, [messages]);

  useEffect(() => {
    console.log("État actuel des messages:", messages);
  }, [messages]);

  return (
    <ChatbotContainer>
      <MessagesContainer
        className="messages-container"
        ref={messagesContainerRef}
        onScroll={handleScroll}
      >
        {messages.map((msg) => (
          <Message key={msg.id} sender={msg.sender}>
            {msg.sender === 'bot' && msg.isTyping ? (
              <TypewriterEffect 
                formattedText={msg.text} 
                onTypingDone={() => handleTypingDone(msg.id)}
              />
            ) : (
              <div dangerouslySetInnerHTML={{ __html: msg.text }} />
            )}
          </Message>
        ))}
        {isTyping && (
          <Message $sender="bot">
            <Spinner />
          </Message>
        )}
        <div ref={messagesEndRef} />
      </MessagesContainer>
      <InputContainer>
        <Input
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
          placeholder="Tapez votre message..."
          disabled={isResponding}
        />
        {isResponding ? (
          <StopButton onClick={stopResponse}>
            stop
          </StopButton>
        ) : (
          <SendButton onClick={sendMessage} disabled={isResponding}>
            <IoSend />
          </SendButton>
        )}
      </InputContainer>
    </ChatbotContainer>
  );
};

export default Chatbot;
