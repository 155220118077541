// Spinner.js
import React from 'react';
import styled, { keyframes } from 'styled-components';

// Animation pour un spinner circulaire moderne
const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const SpinnerContainer = styled.div`
  display: inline-block;
  width: 10px; /* Ajuster la taille selon vos préférences */
  height: 10px;
  border: 4px solid rgba(255, 255, 255, 0.3); /* Couleur des bords du cercle */
  border-radius: 50%;
  border-top: 4px solid #4a90e2; /* Couleur de la partie animée */
  animation: ${spin} 1s ease-in-out infinite; /* Animation fluide et continue */
`;

export const Spinner = () => {
  return <SpinnerContainer />;
};
